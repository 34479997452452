import request from '@/utils/request'


// 查询分销商列表
export function listDistributor(query) {
  return request({
    url: '/user/user-distributors/list',
    method: 'get',
    params: query
  })
}

// 查询分销商分页
export function pageDistributorData(query) {
  return request({
    url: '/user/user-distributors/pageDistributorData',
    method: 'get',
    params: query
  })
}

export function getSecondUserDatas(query) {
  return request({
    url: '/recharge/recharge-orders/getUserDatas',
    method: 'get',
    params: query
  })
}

export function pageDistributorMoney(query) {
  return request({
    url: '/user/user-distributors/pageDistributorMoney',
    method: 'get',
    params: query
  })
}


export function deleteMoneys(query) {
  return request({
    url: '/user/user-distributors/deleteMoneys',
    method: 'get',
    params: query
  })
}

export function getAccountList(query) {
  return request({
    url: '/accountBill/accountBills/getAccountList',
    method: 'get',
    params: query
  })
}

export function pageSecondDistributorData(query) {
  return request({
    url: '/user/user-distributors/pageSecondDistributorData',
    method: 'get',
    params: query
  })
}

// 查询分销商详细
export function getDistributor(data) {
  return request({
    url: '/user/user-distributors/detail',
    method: 'get',
    params: data
  })
}

// 新增分销商
export function addDistributor(data) {
  return request({
    url: '/user/user-distributors/add',
    method: 'post',
    data: data
  })
}

// 修改分销商
export function updateDistributor(data) {
  return request({
    url: '/user/user-distributors/edit',
    method: 'post',
    data: data
  })
}

// 删除分销商
export function delDistributor(data) {
  return request({
    url: '/user/user-distributors/delete',
    method: 'post',
    data: data
  })
}
